import React, { FC, memo, useMemo } from 'react'
import { GenericComponentProps, ResultTeaserCompetitionProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { WidgetTrackingIdentifier } from '@sport1/bets-service-client'
import { MatchClientModel } from '@sport1/types/sportData'
import { PercentageType, PixelType, ResponsiveType } from '@sport1/news-styleguide/Types'
import { spacings } from '@sport1/news-styleguide/spacings'
import CompetitionCardActionButtons from './ActionButtons'
import CompetitionsCardPartnerButton from '@/components/ResultTeaserGeneric/CompetitionCard/PartnerButton'
import CompetitionCardMatches from '@/components/ResultTeaserGeneric/CompetitionCard/MatchList'
import CompetitionCardHeadline from '@/components/ResultTeaserGeneric/CompetitionCard/Headline'
import { UseResultTeaserTrackingReturnProps } from '@/components/ResultTeaserGeneric/utils/useResultTeaserTracking'

export type ResultTeaserCompetitionCardProps = ResultTeaserCompetitionProps &
    Pick<UseResultTeaserTrackingReturnProps, 'trackMatchCardClick'> &
    Partial<Pick<UseResultTeaserTrackingReturnProps, 'trackBetImpressionOnView'>> &
    Pick<GenericComponentProps, 'fragmentMeta'> & {
        expandButton?: boolean
        forceMobileView?: boolean
        minify?: boolean
        testID?: string
        width: ResponsiveType<number | PixelType | PercentageType>
        trackingCreation: WidgetTrackingIdentifier
        positionInSlider?: number
        variant: 'slider' | 'card'
    }

const ResultTeaserCompetitionCard: FC<ResultTeaserCompetitionCardProps> = ({
    matches,
    expandButton,
    forceMobileView,
    maxContentCount = 3,
    tags,
    meta,
    minify,
    trackBetImpressionOnView,
    trackMatchCardClick,
    width,
    fragmentMeta,
    trackingCreation,
    positionInSlider = 0,
    variant,
    testID,
}) => {
    const tag = tags?.[0]
    const testIdPrefix = testID ?? `teaserCard_${tag?.contextId}`
    const groupedContent = useMemo(
        () =>
            matches.reduce(
                (prev, cur) => {
                    ;(prev[cur?.['roundTitle'] ?? ''] = prev[cur?.['roundTitle'] ?? ''] || []).push(
                        cur
                    )
                    return prev
                },
                {} as Record<string, MatchClientModel[]>
            ),
        [matches]
    )
    return (
        <NonFlexingContainer testID={testIdPrefix} width={width}>
            {Object.keys(groupedContent).map(key => {
                const matches = groupedContent[key]
                const firstMatch = matches?.[0]

                return (
                    <div style={{ paddingInline: spacings['spacing-3'] }} key={key}>
                        <CompetitionCardHeadline
                            tag={tag}
                            testID={`${testIdPrefix}-${firstMatch?.id}-headline`}
                            match={firstMatch}
                        />
                        <CompetitionCardMatches
                            testID={`${testIdPrefix}-${firstMatch?.id}-matches`}
                            matches={matches}
                            tag={tag}
                            trackingInteractionLabel="result-teaser"
                            trackingCreation={trackingCreation}
                            trackBetImpressionOnView={trackBetImpressionOnView}
                            trackMatchCardClick={trackMatchCardClick}
                            forceMobileView={forceMobileView}
                            minify={minify}
                            variant={variant}
                            competitionCardTrackingData={meta?.tracking}
                            positionInSlider={positionInSlider}
                        />
                    </div>
                )
            })}
            {tag && !tag.href?.includes('olympia') && (
                <CompetitionCardActionButtons
                    tag={tag}
                    matches={matches}
                    maxContentCount={maxContentCount}
                    width={expandButton || forceMobileView ? '100%' : ['100%', '343px', '343px']}
                    positionInSlider={positionInSlider}
                    variant={variant}
                    trackMatchCardClick={trackMatchCardClick}
                    competitionCardTrackingData={meta?.tracking}
                />
            )}
            <CompetitionsCardPartnerButton
                width={width}
                tag={tag}
                competition={matches[0]?.competition}
                sport={matches[0]?.sport}
                partnerButtonFragmentMeta={fragmentMeta}
            />
        </NonFlexingContainer>
    )
}

export default memo(ResultTeaserCompetitionCard)
