import React, { useCallback, VFC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { LabelProps, TagProps, TrackingDataProps } from '@sport1/types/web'
import { MatchClientModel } from '@sport1/types/sportData'
import { spacings } from '@sport1/news-styleguide/spacings'
import SportEvent from '@/components/SportEvent'
import { ResultTeaserCompetitionCardProps } from '@/components/ResultTeaserGeneric/CompetitionCard'

type CompetitionCardMatchesProps = Pick<
    ResultTeaserCompetitionCardProps,
    | 'forceMobileView'
    | 'minify'
    | 'trackBetImpressionOnView'
    | 'trackMatchCardClick'
    | 'trackingCreation'
    | 'positionInSlider'
    | 'variant'
    | 'testID'
> & {
    competitionCardTrackingData?: TrackingDataProps[]
    matches: MatchClientModel[]
    tag?: TagProps | LabelProps
    trackingInteractionLabel: string
}

const CompetitionCardMatches: VFC<CompetitionCardMatchesProps> = ({
    matches,
    trackMatchCardClick,
    tag,
    trackBetImpressionOnView,
    trackingCreation,
    minify,
    forceMobileView,
    positionInSlider = 0,
    variant,
    trackingInteractionLabel,
    competitionCardTrackingData,
    testID,
}) => {
    const handleTrackCardClick = useCallback(
        ({
            match,
            positionInSlider,
            variant,
            competitionCardTrackingData,
            trackMatchCardClick,
        }: {
            match: MatchClientModel
            positionInSlider: number
            variant: 'slider' | 'card'
            competitionCardTrackingData: TrackingDataProps[] | undefined
            trackMatchCardClick: CompetitionCardMatchesProps['trackMatchCardClick']
        }) => {
            if (trackMatchCardClick) {
                trackMatchCardClick({
                    competition: match.competition,
                    positionInSlider,
                    type: variant,
                    competitionCardTrackingData,
                })
            }
        },
        []
    )

    return (
        <NonFlexingContainer marginY="spacing-1" testID={testID}>
            {matches.map(match => (
                <div
                    key={`${match.homeTeam?.shortName}_${match.awayTeam?.shortName}_${match.scheduledStartTime}`}
                    style={{ marginBottom: spacings['spacing-3'] }}
                >
                    <SportEvent
                        forceMobileView={forceMobileView}
                        match={match}
                        minify={minify}
                        scope="ticker" // TODO: correct scope?
                        tag={tag}
                        testID={`result_teaser_match_card_${match.id}`}
                        trackBetImpression={trackBetImpressionOnView}
                        trackCardClick={() =>
                            handleTrackCardClick({
                                match,
                                positionInSlider,
                                variant,
                                competitionCardTrackingData,
                                trackMatchCardClick,
                            })
                        }
                        trackingCreation={trackingCreation}
                        trackingInteractionLabel={trackingInteractionLabel}
                    />
                </div>
            ))}
        </NonFlexingContainer>
    )
}

export default CompetitionCardMatches
