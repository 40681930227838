import React, { FC, ReactElement, useCallback, useRef } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { DimensionProps } from '@sport1/news-styleguide/Types'
import Tab from '@sport1/news-styleguide/Tab'
import { TodayLiveCategoryModel } from '@sport1/types/web'
import Touchable from '@sport1/news-styleguide/Touchable'
import Font from '@sport1/news-styleguide/Font'
import colorPattern from '@sport1/news-styleguide/colorPattern'
import useCurrentDeviceSize from '../../../hooks/useCurrentDeviceSize'
import sportIdentifiersConfig from '@/utils/sportIdentifiers/SportIdentifiersConfig'

export interface CategoryPickerProps extends DimensionProps {
    testID?: string
    items: TodayLiveCategoryModel[]
    selectedCategory?: TodayLiveCategoryModel
    onSelect: (item: TodayLiveCategoryModel) => void
}

const keyExtractor = ({ name }: TodayLiveCategoryModel) => `category-chip-${name}`

const CategoryPicker: FC<CategoryPickerProps> = ({
    width,
    testID,
    items,
    selectedCategory,
    onSelect,
}) => {
    const { deviceSizeDesktop } = useCurrentDeviceSize()
    const scrollRef = useRef<HTMLDivElement>(null)
    const handleSelect = useCallback(
        (item: TodayLiveCategoryModel) => {
            if (onSelect) {
                onSelect(item)
            }
            const index = items.findIndex(i => i.id === item.id)
            if (scrollRef.current && index !== -1) {
                const offset = index * (scrollRef.current.scrollWidth / items.length)
                scrollRef.current.scrollTo({
                    left: offset - 75,
                    behavior: 'smooth',
                })
            }
        },
        [onSelect, items]
    )

    const renderItem = useCallback(
        (item: TodayLiveCategoryModel): ReactElement | null => {
            const isActive =
                item.id === (selectedCategory?.id || sportIdentifiersConfig.soccer.id) ||
                item.name === selectedCategory?.name
            if (!item.name) return null
            return isActive ? (
                <div style={{ zIndex: 1 }}>
                    <Tab
                        key={keyExtractor(item)}
                        testID={`category-${item.id}`}
                        title={item.name}
                        onPress={() => handleSelect(item)}
                        isActive={isActive}
                    />
                </div>
            ) : (
                <Touchable
                    onPress={() => handleSelect(item)}
                    backgroundColor={deviceSizeDesktop ? colorPattern.ghost : colorPattern.pearl}
                >
                    <NonFlexingContainer height="100%" centerVertical>
                        <Font
                            fontFamilyVariant="Sport1-CondensedBlackItalic"
                            className="h-[100%] justify-center px-[12px] uppercase"
                            fontSize="font-size-3"
                        >
                            {item.name}
                        </Font>
                    </NonFlexingContainer>
                </Touchable>
            )
        },
        [handleSelect, selectedCategory?.id, selectedCategory?.name, deviceSizeDesktop]
    )

    return (
        <NonFlexingContainer testID={testID} width={width} overflow="auto">
            <NonFlexingContainer
                testID={`${testID}-horizontal-scroll`}
                backgroundColor={deviceSizeDesktop ? colorPattern.ghost : colorPattern.pearl}
                position="relative"
            >
                <div
                    ref={scrollRef}
                    style={{ display: 'flex', scrollbarWidth: 'none', overflow: 'auto' }}
                >
                    {items.map(renderItem)}
                </div>
                {!deviceSizeDesktop && (
                    <div
                        data-testid="bottom-border"
                        style={{
                            height: '2px',
                            width: ' 100%',
                            backgroundColor: colorPattern.ghost,
                            position: 'absolute',
                            bottom: 0,
                        }}
                    />
                )}
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default CategoryPicker
