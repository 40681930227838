import React, { VFC } from 'react'
import { FragmentMetaModel, LabelProps, TagProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { CompetitionClientModel, SportClientModel } from '@sport1/types/sportData'
import { PercentageType, PixelType, ResponsiveType } from '@sport1/news-styleguide/Types'
import PartnerButton from '@/components/PartnerButton'

type CompetitionsCardPartnerButtonProps = {
    partnerButtonFragmentMeta?: FragmentMetaModel[]
    width: ResponsiveType<number | PixelType | PercentageType>
    tag?: TagProps | LabelProps
    competition?: CompetitionClientModel
    sport?: SportClientModel
}

const CompetitionsCardPartnerButton: VFC<CompetitionsCardPartnerButtonProps> = ({
    competition,
    sport,
    partnerButtonFragmentMeta,
    tag,
    width,
}) => {
    return partnerButtonFragmentMeta ? (
        <NonFlexingContainer
            testID={`teaserCard_${tag?.contextId}-partnerButtonSpacer`}
            width={width}
            paddingX="spacing-3"
        >
            <PartnerButton
                competition={competition}
                sport={sport}
                fragmentMeta={partnerButtonFragmentMeta}
            />
        </NonFlexingContainer>
    ) : null
}

export default CompetitionsCardPartnerButton
