import React, { useEffect, useRef, VFC } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { WidgetTrackingIdentifier } from '@sport1/bets-service-client'
import { GenericComponentProps, ResultTeaserCompetitionProps } from '@sport1/types/web'
import useCurrentDeviceSize from '../../../../hooks/useCurrentDeviceSize'
import { UseResultTeaserTrackingReturnProps } from '@/components/ResultTeaserGeneric/utils/useResultTeaserTracking'
import ResultTeaserCompetitionCard from '@/components/ResultTeaserGeneric/CompetitionCard'

type SingleCompetitionProps = Omit<
    UseResultTeaserTrackingReturnProps,
    'onViewableItemsChanged' | 'trackBetImpressionOnView'
> &
    Pick<GenericComponentProps, 'fragmentMeta'> & {
        trackingCreation: WidgetTrackingIdentifier
        testID?: string
        competitionCard: ResultTeaserCompetitionProps
    }

const SingleCompetition: VFC<SingleCompetitionProps> = ({
    competitionCard,
    trackMatchCardClick,
    trackMatchCardImpression,
    trackingCreation,
    fragmentMeta,
    testID,
}) => {
    const initial = useRef(true)
    const { deviceSizeDesktop } = useCurrentDeviceSize()

    useEffect(() => {
        if (initial.current) {
            initial.current = false
            trackMatchCardImpression({
                competition: competitionCard.matches[0]?.competition,
                type: 'card',
                positionInSlider: 0,
                competitionCardTrackingData: competitionCard.meta?.tracking,
            })
        }
    }, [competitionCard, trackMatchCardImpression])

    return (
        <NonFlexingContainer
            testID={`${testID}-single-card`}
            paddingRight={deviceSizeDesktop ? 'spacing-3' : undefined}
        >
            <ResultTeaserCompetitionCard
                testID="competition-card-0"
                key={`result-teaser-generic-${competitionCard.matches.map(m => m.id).join('-')}`}
                expandButton
                forceMobileView
                trackMatchCardClick={trackMatchCardClick}
                width="100%"
                trackingCreation={trackingCreation}
                fragmentMeta={fragmentMeta}
                variant="card"
                {...competitionCard}
            />
        </NonFlexingContainer>
    )
}

export default SingleCompetition
