import React, { VFC } from 'react'
import {
    LabelProps,
    ResultTeaserCompetitionProps,
    TagProps,
    TrackingDataProps,
} from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { PercentageType, PixelType, ResponsiveType } from '@sport1/news-styleguide/Types'
import Touchable from '@sport1/news-styleguide/Touchable'
import Font from '@sport1/news-styleguide/Font'
import colorPattern from '@sport1/news-styleguide/colorPattern'
import { spacings } from '@sport1/news-styleguide/spacings'
import getActionButtons from './util/getActionButtons'
import isKnockoutStage from '@/utils/match/isKnockoutStage'
import getSportIdentifier from '@/utils/match/getSportIdentifier'
import { UseResultTeaserTrackingReturnProps } from '@/components/ResultTeaserGeneric/utils/useResultTeaserTracking'
import { ResultTeaserCompetitionCardProps } from '@/components/ResultTeaserGeneric/CompetitionCard'

type Props = Pick<ResultTeaserCompetitionProps, 'matches' | 'maxContentCount'> &
    Pick<UseResultTeaserTrackingReturnProps, 'trackMatchCardClick'> &
    Pick<ResultTeaserCompetitionCardProps, 'positionInSlider' | 'variant'> & {
        width: ResponsiveType<number | PixelType | PercentageType>
        tag?: TagProps | LabelProps
        competitionCardTrackingData?: TrackingDataProps[]
    }

const CompetitionCardActionButtons: VFC<Props> = ({
    matches,
    maxContentCount = 3,
    width,
    tag,
    trackMatchCardClick,
    positionInSlider = 0,
    variant,
    competitionCardTrackingData,
}) => {
    const isMaxContentCountBiggerThanMatchesLength = maxContentCount > matches.length
    const sportIdentifier = matches[0] ? getSportIdentifier(matches[0]) : undefined
    const sportId = matches[0]?.sport?.id
    const hasStandings = matches.some(
        match => match.competition?.hasStandings && !isKnockoutStage(match?.roundType)
    )

    const onPress = React.useCallback(() => {
        if (matches?.length && matches[0]) {
            trackMatchCardClick?.({
                competition: matches[0].competition,
                positionInSlider,
                type: variant,
                competitionCardTrackingData,
            })
        }
    }, [competitionCardTrackingData, matches, positionInSlider, trackMatchCardClick, variant])

    return (
        <NonFlexingContainer
            width={width}
            horizontal
            alignRight
            marginX={!isMaxContentCountBiggerThanMatchesLength ? undefined : 'auto'}
        >
            {getActionButtons({
                hasStandings,
                matches,
                sportIdentifier,
                sportId,
                tag,
            }).map(button => (
                <Touchable
                    key={button.title}
                    onPress={onPress}
                    paddingY={spacings['spacing-2']}
                    paddingX={spacings['spacing-6']}
                    href={button.href}
                >
                    <Font fontVariant="Body-L" color={colorPattern.sky} underline>
                        {button.title}
                    </Font>
                </Touchable>
            ))}
        </NonFlexingContainer>
    )
}

export default CompetitionCardActionButtons
